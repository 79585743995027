import React, { useEffect, useState } from 'react';
import QRScanner from 'react-qr-scanner';

const Scanner = ({ onClose, onDataScanned }) => {

    const [constraints, setConstraints] = useState({ facingMode: 'user' });

    useEffect(() => {
        navigator.permissions.query({ name: 'camera' })
            .then(permissionStatus => {
                if (permissionStatus.state === 'denied') {
                    alert('Camera access is denied. Please enable it in your browser settings.');
                    onClose();
                }
            });
    }, []);


    const handleScan = data => {
        if (data) {
            onDataScanned(data.text);
            onClose();
        }
    };

    const handleError = err => {
        console.error(err);
        alert(`An error occurred: ${err.message}`);
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4">
                <button onClick={onClose}>Close</button>
                <QRScanner
                    delay={300}
                    constraints={{ video: { facingMode: 'environment' } }}
                    onError={handleError}
                    onScan={handleScan}
                />
            </div>
        </div>
    );
};

export default Scanner;
