// NavigationButtons.js
import { useNavigate } from 'react-router-dom';

const Menu = () => {
  const navigate = useNavigate();
  
  return (
    <div className="w-full min-w-2xl max-w-2xl w-[98%]  p-8 bg-white flex justify-between border border-gray-100 rounded-b-lg">
      <button 
        className="bg-green-500 text-white px-8 p-4 rounded-full focus:outline-none focus:ring-4 focus:ring-green-300" 
        onClick={() => navigate("/uitlenen")}
      >
        UITLENEN 
      </button>
      
      {/* <span className="m-8"></span> */}
      
      <button 
        className="bg-red-500 text-white px-8 p-4 rounded-full focus:outline-none focus:ring-4 focus:ring-red-300" 
        onClick={() => navigate("/terugbrengen")}
      >
        TERUGBRENGEN
      </button>
    </div>
  );
};

export default Menu;
