import React, { useEffect, useState } from 'react';
import Scanner from '../components/Scanner';
import ScannedItems from '../components/ScannedItems';

const snipeProxyUri = process.env.REACT_APP_SNIPE_PROXY_URI || 'http://localhost:3001';

const Terugbrengen = () => {
    const [isScannerOpen, setIsScannerOpen] = useState(false);
    const [scannedData, setScannedData] = useState(null);

    const [consumables, setConsumables] = useState([]);
    const [hardwares, setHardwares] = useState([]);

    const handleOpenScanner = () => setIsScannerOpen(true);
    const handleCloseScanner = () => setIsScannerOpen(false);
    const handleDataScanned = (data) => setScannedData(data);

    // helper functions

    function getKeyFromUrl(url) {
        const parts = url.split('/');
        const lastPart = parts.pop();
        const lastInt = parseInt(lastPart, 10);

        if (isNaN(lastInt)) {
            return null;
        }

        return lastInt;
    }

    function getTypeFromUrl(url) {
        if (url.includes('/hardware/')) {
            return 'hardware';
        }
        else if (url.includes('/users/')) {
            return 'users';
        }
        else if (url.includes('/consumables/')) {
            return 'consumables';
        }
    }

    function deleteConsumableFromList(id) {
        setConsumables(consumables.filter(consumable => consumable.id !== id));
    }

    function deleteMultipleConsumablesFromList(ids) {
        setConsumables(consumables.filter(consumable => !ids.includes(consumable.id)));
    }

    function deleteHardwareFromList(id) {
        setHardwares(hardwares.filter(hardware => hardware.id !== id));
    }

    function deleteMultipleHardwaresFromList(ids) {
        setHardwares(hardwares.filter(hardware => !ids.includes(hardware.id))); 
    }


    // api functions

    async function getConsumableFromId(id) {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        let api_result

        try {
            let response;

            response = await fetch(`${snipeProxyUri}/api/consumables/${id}`, requestOptions);

            if (response && response.ok) {
                console.log(response)
                api_result = await response.json();
            }
        }

        catch (error) {
            console.error('Error fetching data:', error);
        }

        let result_consumable = {
            id: api_result.id,
            name: api_result.name,
            qty: api_result.qty,
        }

        return result_consumable
    }


    async function addConsumableToList(data) {

        let id = getKeyFromUrl(data);

        let result_consumable = await getConsumableFromId(id);


        let input = prompt("Hoeveel zijn er teruggebracht?", "1");

        if (input === null || input === "" || input === undefined) {
            return;
        }

        if (input <= 0) {
            alert("Je kan niet minder dan 1 terubbrengen!");
            input = prompt("Hoeveel zijn er teruggebracht?", "1");
        }

        const new_qty = parseInt(input);

        setConsumables([...consumables, { id: result_consumable.id, name: result_consumable.name, qty: new_qty }]);

        return
    }

    async function addHardwareToList(data) {

        let id = getKeyFromUrl(data);

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        let api_result

        try {

            let response;

            response = await fetch(`${snipeProxyUri}/api/hardware/${id}`, requestOptions);

            if (response && response.ok) {
                console.log(response)
                api_result = await response.json();
                console.log(api_result)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }

        let result_hardware = {
            id: api_result.id,
            name: api_result.name,
        }

        console.log(result_hardware)

        setHardwares([...hardwares, { id: result_hardware.id, name: result_hardware.name }]);
        return
    }

    function returnItems() {

        const returnItems = async () => {

            let consumablesToDelete = []

            for (const consumable of consumables) {

                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                };
                try {

                    let current_consumable = await getConsumableFromId(consumable.id);

                    requestOptions.body = JSON.stringify({ qty: current_consumable.qty + consumable.qty });
                    const response = await fetch(`${snipeProxyUri}/api/consumables/${consumable.id}`, requestOptions);

                    if (response.ok) {
                        const data = await response.json();
                        
                        if (data.status === "error") {
                            alert(`Er is een probleem met het terugbrengen van ${consumable.name}`);
                        } else {
                            consumablesToDelete.push(consumable.id);
                        }
                        
                        
                    } else {
                        console.log(`Server responded with status: ${response.status} for consumable ID ${consumable.id}`);
                    }
                } catch (error) {
                    console.error(`There was a problem updating consumable with ID ${consumable.id}:`, error);
                }

            }

            deleteMultipleConsumablesFromList(consumablesToDelete);


            let hardwaresToDelete = []

            for (const hardware of hardwares) {

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                };

                try {

                    const response = await fetch(`${snipeProxyUri}/api/hardware/${hardware.id}/checkin`, requestOptions);

                    if (response.ok) {

                        const data = await response.json();
                        console.log(`Successfully lent hardware with ID ${hardware.id}:`, data);

                        if (data.status === "error") {
                            alert(`Er is een probleem met het terugbrengen van ${hardware.name}`);
                        } else {
                            hardwaresToDelete.push(hardware.id);
                        }

                    }
                    else {
                        console.log(`Server responded with status: ${response.status} for hardware ID ${hardware.id}`);
                    }

                }

                catch (error) {
                    console.error(`There was a problem lending hardware with ID ${hardware.id}:`, error);
                }

            }

            deleteMultipleHardwaresFromList(hardwaresToDelete);

        }

        returnItems();

    };

    // handle scan
    useEffect(() => {
        const handleScan = async () => {

            if (!scannedData) return;

            const typeOfScan = getTypeFromUrl(scannedData);

            if (!typeOfScan) return;

            switch (typeOfScan) {
                case 'hardware':
                    console.log("hardware")
                    addHardwareToList(scannedData);
                    setScannedData(null);
                    break;
                case 'users':
                    console.log("users")
                    setScannedData(null);
                    break;
                case 'consumables':
                    console.log("consumables")
                    addConsumableToList(scannedData);
                    setScannedData(null);
                    break;
                default:
                    break;
            }
            return
        }

        handleScan();

    }, [scannedData]);

    return (
        <div>

            <button
                className="bg-blue-500 text-white p-6 w-full rounded-lg hover:bg-blue-700 mt-4 mb-3"
                onClick={handleOpenScanner}
            >
                Scan een item
            </button>

            <ScannedItems
                hardwares={hardwares}
                consumables={consumables}
                onDeleteConsumable={deleteConsumableFromList}
                onDeleteHardware={deleteHardwareFromList}
                onComplete={returnItems}
            />



            {isScannerOpen && (
                <Scanner
                    onClose={handleCloseScanner}
                    onDataScanned={handleDataScanned}
                />
            )}
        </div>
    );
};

export default Terugbrengen;
