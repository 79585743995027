import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './components/Menu';
import Uitlenen from './views/Uitlenen';
import Terugbrengen from './views/Terugbrengen';

function App() {
  return (
    <Router>
      <div className="h-screen flex flex-col justify-center items-center bg-gray-100 p-4">
        <div className="flex-grow bg-white p-8 w-full min-w-2xl max-w-2xl overflow-y-auto">
          <h1 className="text-3xl font-extrabold mb-4">Magazijn</h1>
          <hr className="mb-4" />
          <div className='container mx-auto'>
            <Routes>
              <Route index element={<Uitlenen />} />
              <Route path="uitlenen" element={<Uitlenen />} />
              <Route path="terugbrengen" element={<Terugbrengen />} />
            </Routes>
          </div>
        </div>
        <Menu />
      </div>
    </Router>
  );
}

export default App;
