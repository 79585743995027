import React from "react";

const ScannedItems = ({ hardwares, consumables, onDeleteHardware, onDeleteConsumable, onComplete }) => {

    return (
        <>

            <div className='m-3 p-5 border-2 border-gray-400 rounded'>
                {((hardwares && hardwares.length > 0) || (consumables && consumables.length > 0)) && (
                    <button
                        className="bg-lime-500 w-full text-lg text-white mt-3 p-6 rounded-lg hover:bg-lime-700 mt-4"
                        onClick={onComplete}
                    >
                        Indienen
                    </button>
                )
                }

                <div className="mb-4 my-4">
                    <h2 className="text-lg font-bold">Artikelen</h2>
                    <div className="overflow-auto h-80 border border-gray-200 rounded">
                        {hardwares && hardwares.length > 0 && (
                            <ul className="p-4">
                                {hardwares.map((hardware, index) => (
                                    <li key={index} className="flex justify-between items-center border-b py-2">
                                        <span>{hardware.name}</span>
                                        <button onClick={() => onDeleteHardware(hardware.id)} className="text-red-500 hover:text-red-700">Verwijderen</button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                <div className="mb-4 my-4">
                    <h2 className="text-lg font-bold">Verbruiksartikelen</h2>
                    <div className="overflow-auto h-80 border border-gray-200 rounded">
                        {consumables && consumables.length > 0 && (
                            <ul className="p-4">
                                {consumables.map((consumable, index) => (
                                    <li key={index} className="flex justify-between items-center border-b py-2">
                                        <span>{consumable.name}</span>
                                        <span>{consumable.qty}</span>
                                        <button onClick={() => onDeleteConsumable(consumable.id)} className="text-red-500 hover:text-red-700">Verwijderen</button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ScannedItems;
