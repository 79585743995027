import React, { useEffect, useState } from 'react';
import Scanner from '../components/Scanner';
import ScannedItems from '../components/ScannedItems';



const snipeProxyUri = process.env.REACT_APP_SNIPE_PROXY_URI || 'http://localhost:3001';

const Uitlenen = () => {
    const [isScannerOpen, setIsScannerOpen] = useState(false);
    const [scannedData, setScannedData] = useState(null);
    const [user, setUser] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const [consumables, setConsumables] = useState([]);
    const [hardwares, setHardwares] = useState([]);

    const handleOpenScanner = () => setIsScannerOpen(true);
    const handleCloseScanner = () => setIsScannerOpen(false);
    const handleDataScanned = (data) => setScannedData(data);


    // helper fuctions

    function getKeyFromUrl(url) {
        const parts = url.split('/');
        const lastPart = parts.pop();
        const lastInt = parseInt(lastPart, 10);

        if (isNaN(lastInt)) {
            return null;
        }

        return lastInt;
    }

    function getTypeFromUrl(url) {
        if (url.includes('/hardware/')) {
            return 'hardware';
        }
        else if (url.includes('/users/')) {
            return 'users';
        }
        else if (url.includes('/consumables/')) {
            return 'consumables';
        }
    }

    function deleteConsumableFromList(id) {
        setConsumables(consumables.filter(consumable => consumable.id !== id));
    }

    function deleteMultipleConsumablesFromList(ids) {
        setConsumables(consumables.filter(consumable => !ids.includes(consumable.id)));
    }

    function deleteHardwareFromList(id) {
        console.log("hardwares", hardwares)
        console.log("id", id)
        setHardwares(hardwares.filter(hardware => hardware.id !== id));
    }

    function deleteMultipleHardwaresFromList(ids) {
        setHardwares(hardwares.filter(hardware => !ids.includes(hardware.id)));
    }


    // api functions

    async function logInUser(data) {
        let id = getKeyFromUrl(data);

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        let api_result

        try {
            let response;

            response = await fetch(`${snipeProxyUri}/api/users/${id}`, requestOptions);

            if (response && response.ok) {
                console.log(response)
                api_result = await response.json();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        let result_user = {
            id: api_result.id,
            name: api_result.name,
            username: api_result.username,
        }

        setUser(result_user);
        return
    }

    async function getConsumableFromId(id) {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        try {
            
            let response = await fetch(`${snipeProxyUri}/api/consumables/${id}`, requestOptions);

            if (response && response.ok) {
                console.log(response)
                return await response.json();
            }
        }

        catch (error) {
            console.error('Error fetching data:', error);
        }

    
    }

    async function addConsumableToList(data) {

        if (!user) {
            alert('Geen gebruiker aangemeld!');
            setScannedData(null);
            return;
        }

        let id = getKeyFromUrl(data);
        let result_consumable = await getConsumableFromId(id);

        if (result_consumable.remaining <= 0) {
            alert("Dit artikel is niet meer beschikbaar!")
            setScannedData(null);
            return;
        }
        
        const maxQty = Math.min(result_consumable.remaining, 20);
        let input = prompt("Hoeveel wil je er lenen? Maximum is: " + maxQty, "1");

        if (input === null || input === "" || input === undefined) {
            return;
        }

        while (input > maxQty || input <= 0) {
            input = prompt("Je gaf geen geldig aantal op! Hoeveel wil je er lenen? Maximum is: " + result_consumable.remaining, "1");
        }
        const new_qty = parseInt(input);

        setConsumables([...consumables, { id: result_consumable.id, name: result_consumable.name, qty: new_qty }]);


        return
    }

    async function addHardwareToList(data) {

        if (!user) {
            alert('Geen gebruiker aangemeld!');
            setScannedData(null);
            return;
        }

        let id = getKeyFromUrl(data);

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        let api_result

        try {

            let response;

            response = await fetch(`${snipeProxyUri}/api/hardware/${id}`, requestOptions);

            if (response && response.ok) {
                console.log(response)
                api_result = await response.json();
                console.log(api_result)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }

        let result_hardware = {
            id: api_result.id,
            name: api_result.name,
        }

        console.log(result_hardware)

        setHardwares([...hardwares, { id: result_hardware.id, name: result_hardware.name }]);
        return
    }

    function lendItems() {

        const lendItems = async () => {
            setSubmitting(true);

            let consumablesToDelete = [];


            for (const consumable of consumables) {

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                };
                
                try {

                    // let current_consumable = await getConsumableFromId(consumable.id);

                    requestOptions.body = JSON.stringify({ 
                        qty: consumable.qty,
                        assigned_to: user.id

                    });
                    const response = await fetch(`${snipeProxyUri}/api/consumables/${consumable.id}/checkout`, requestOptions);

                    if (response.ok) {
                        const data = await response.json();
                        console.log(`Successfully lent consumable with ID ${consumable.id}:`, data);
                        consumablesToDelete.push(consumable.id);
                    } else {
                        console.log(`Server responded with status: ${response.status} for consumable ID ${consumable.id}`);
                    }
                } catch (error) {
                    console.error(`There was a problem updating consumable with ID ${consumable.id}:`, error);
                }

            }


            deleteMultipleConsumablesFromList(consumablesToDelete);

            let hardwaresToDelete = [];

            for (const hardware of hardwares) {

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                };

                try {

                    requestOptions.body = JSON.stringify({ assigned_user: user.id });

                    const response = await fetch(`${snipeProxyUri}/api/hardware/${hardware.id}/checkout`, requestOptions);

                    if (response.ok) {
                        const data = await response.json();
                        if (data.status === "error") {
                            console.log(`Server responded with status: ${response.status} for hardware ID ${hardware.id}`);
                            alert("Artikel is reeds uitgecheckt! [" + hardware.id +  "] hardware:  " + hardware.name + ". Gelieve deze eerst in te checken.")

                        } else {
                            console.log(`Successfully lent hardware with ID ${hardware.id}:`, data);
                            hardwaresToDelete.push(hardware.id);
                        }
                    }
                    else {
                        console.log(`Server responded with status: ${response.status} for hardware ID ${hardware.id}`);
                    }

                }

                catch (error) {
                    console.error(`There was a problem lending hardware with ID ${hardware.id}:`, error);
                }

            }

            deleteMultipleHardwaresFromList(hardwaresToDelete);
            setSubmitting(false);
        }

        lendItems();

    };


    // handle scan
    useEffect(() => {
        const handleScan = async () => {

            if (!scannedData) return;

            const typeOfScan = getTypeFromUrl(scannedData);

            if (!typeOfScan) return;

            switch (typeOfScan) {
                case 'hardware':
                    console.log("hardware")
                    addHardwareToList(scannedData);
                    setScannedData(null);
                    break;
                case 'users':
                    console.log("users")
                    logInUser(scannedData);
                    setScannedData(null);
                    break;
                case 'consumables':
                    console.log("consumables")
                    addConsumableToList(scannedData);
                    setScannedData(null);
                    break;
                default:
                    break;
            }
            return
        }

        handleScan();

    }, [scannedData]);



    return (
        
        <div className="container mx-auto my-10">
            <button
                className="bg-orange-600 text-white p-6 rounded-lg hover:bg-orange-700 mb-4"
                onClick={handleOpenScanner}
            >
                {user ? 'Andere gebruiker' : 'Aanmelden'}
            </button>


            {user && (
                <div className='py-4 px-8 border-2 border-black'>
                    <div className="">
                        <p className="text-xl font-semibold">Aangemelde gebruiker</p>
                        <p className="text-lg">{user.username}</p>

                    </div>
                </div>

            )}
            {user && (
                <button
                    className="bg-blue-500 text-white p-6 w-full rounded-lg hover:bg-blue-700 mt-4 mb-3"
                    onClick={handleOpenScanner}
                >
                    Scan item
                </button>
            )}
            
            {submitting ? (
                <div className="bg-gray-500 text-white p-6 w-full rounded-lg">
                    Even geduld... 
                </div>
            ) : (
                <ScannedItems
                    hardwares={hardwares}
                    consumables={consumables}
                    onDeleteConsumable={deleteConsumableFromList}
                    onDeleteHardware={deleteHardwareFromList}
                    onComplete={lendItems}
                />
            )}


            {isScannerOpen && (
                <Scanner
                    onClose={handleCloseScanner}
                    onDataScanned={handleDataScanned}
                />
            )}
        </div>
    );
};

export default Uitlenen;
